import React from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'

import Heading from '@components/atoms/heading/heading'
import Container from '@layouts/container/container'
import Text from '@components/atoms/text/text'

import styles from './error.module.scss'

const ErrorTemplate = () => {
    const iconClass = cn('icon icon-error', styles.icon)
    return (
        <Container>
            <Heading className={styles.heading} text="Uh oh, that's an error!" />
            <i className={iconClass}></i>
            <Text className={styles.text}>We can't seem to be able to find that page. Please check the address and try again.</Text>
            <Link className={styles.link} to="/">
                Click here to go home
            </Link>
        </Container>
    )
}

export default ErrorTemplate
